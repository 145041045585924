.paypal-finalize-dialog {

    md-tabs-wrapper {
        display: none;
    }

    md-dialog-content {
        max-width: 550px;
    }

    .processingContent, .resultContent {
        min-height: 300px;

        .detail {
            text-align: center;
        }
    }

    .min-width {

        min-width: 500px;

        .fpop-media-layout-xs & {
            min-width: initial;
        }
    }

}





.paypal-button-text {
    padding: 0px 6px;
}